import { type VariantProps, cva } from 'class-variance-authority';

export { default as Button } from './Button.vue';

export const buttonVariants = cva(
  [
    'font-medium',
    'rounded',
    'items-center',
    'justify-center',
    'appearance-none',
    'relative',
    'border',
    'select-none',
    'cursor-pointer',
    'transition-colors',
    'duration-100',
    'cubic-smooth',
    'disabled:cursor-not-allowed',
    'disabled:opacity-50',
  ],
  {
    variants: {
      destructive: {
        true: [],
        false: [],
      },
      expand: {
        true: ['w-full', 'flex'],
        false: ['inline-flex'],
      },
      size: {
        sm: ['py-1', 'px-4', 'text-sm'],
        md: ['px-4', 'py-2', 'text-base'],
        lg: ['py-2', 'px-6', 'text-lg'],
      },
      variant: {
        filled: ['text-white'],
        outline: [
          'bg-white',
          'hover:bg-primary-100',
          'border-primary-500',
        ],
        plain: ['bg-transparent', 'border-transparent'],
      },
    },
    compoundVariants: [
      {
        destructive: false,
        variant: 'outline',
        class: ['text-primary-500', 'hover:border-primary-500'],
      },
      {
        destructive: false,
        variant: 'filled',
        class: [
          'bg-primary-500',
          'border-primary-500',
          'hover:bg-primary-600',
        ],
      },
      {
        destructive: true,
        variant: 'plain',
        class: ['text-danger'],
      },
      {
        destructive: true,
        variant: 'filled',
        class: ['bg-error', 'border-error', 'hover:bg-error-700'],
      },
      {
        destructive: true,
        variant: 'outline',
        class: ['text-error', 'border-error', 'hover:bg-error-100'],
      },
    ],
    defaultVariants: {
      size: 'md',
      variant: 'outline',
    },
  },
);

export type ButtonVariants = VariantProps<typeof buttonVariants>;
